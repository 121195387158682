import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import classes from '../../Finances.module.scss';
import { vendorsIcons } from '../../helpers/helpers';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import globalClasses from '@/containers/App/Global.module.scss';
const Detailing = ({ calculateVendorTotalIncome }) => {
    var _a;
    const [isOpen, setIsOpen] = useState(true);
    const intl = useIntl();
    return (React.createElement("div", { className: classes.detailing },
        React.createElement("div", { className: classes.detailingHead },
            React.createElement("div", { className: classes.detailingTitle }, intl.formatMessage({ id: 'common.detailing', defaultMessage: 'Деталізація' })),
            React.createElement("div", { className: classes.detailingBtn, onClick: () => { setIsOpen((prev) => !prev); } },
                React.createElement("div", { className: `${globalClasses.openCloseBtn} ${isOpen ? globalClasses.open : ''}` }))),
        isOpen && (React.createElement("div", { className: classes.detailingBody }, calculateVendorTotalIncome && ((_a = Object.entries(calculateVendorTotalIncome)) === null || _a === void 0 ? void 0 : _a.map((item) => (React.createElement("div", { key: uuidv4(), className: classes.detailingRow },
            React.createElement("div", { className: classes.detailingRowCell },
                React.createElement("div", { className: classes.detailingRowCellName },
                    vendorsIcons(item[0]),
                    React.createElement("span", null, item[0]))),
            React.createElement("div", { className: classes.detailingRowCell },
                React.createElement("div", { className: classes.detailingRowCellValue },
                    React.createElement(ValueWithCurrency, { value: item[1], separationValue: true, minimumFractionDigits: true, withZero: true })))))))))));
};
export default Detailing;
