import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classes from './Header.module.scss';
import MenuItems from './MenuItems/MenuItems';
import Lang from '@/atoms/Lang/Lang';
import { EDIT_USER_LANG } from '@/graphql/auth';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import ExitIcon from '@/icons/exit.svg';
import MenuIcon from '@/icons/menu.svg';
import { getErrorMessage } from '@/utils/validateMessages';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isMenu, setIsMenu] = useState(false);
    const intl = useIntl();
    const { firstName } = useAppSelector((state) => state.auth.user);
    const { authLogoutRequest, changeLang } = useAppDispatch();
    const [editUser] = useMutation(EDIT_USER_LANG);
    const handleChangeLang = (lng) => {
        editUser({
            variables: { editUserInput: { lang: lng } }
        }).then(() => {
            changeLang(lng);
            setIsMenu(false);
        }, (err) => { getErrorMessage(err, intl); });
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > 0;
            setIsScrolled(scrolled);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        setIsAnimating(true);
        const timeoutId = setTimeout(() => {
            setIsAnimating(false);
        }, 1100);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isScrolled]);
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: `${isScrolled ? classes.scroll : ''} ${isAnimating ? classes.animation : ''} ${classes.header}` },
            React.createElement("div", { className: classes.box },
                React.createElement("div", { className: classes.userIcon, onClick: () => { setIsMenu(true); } },
                    React.createElement(MenuIcon, null)),
                React.createElement("div", { className: classes.theme }))),
        React.createElement("div", { className: `${classes.nav} ${isMenu ? classes.navOpen : ''}` },
            React.createElement("div", { className: classes.navHead },
                React.createElement("div", { className: classes.navHeadTop },
                    React.createElement("div", { className: classes.navHeadTopLeft },
                        React.createElement("div", { className: classes.closeNav, onClick: () => { setIsMenu(false); } },
                            React.createElement(ArrowLeftIcon, null)),
                        React.createElement("div", { className: classes.balance },
                            React.createElement("div", { className: classes.balanceTitle }, intl.formatMessage({ id: 'common.balanceTitle', defaultMessage: 'Баланс' })),
                            React.createElement("div", { className: classes.balanceValue }, "\u20B40.00"))),
                    React.createElement("div", { className: classes.navHeadTopRight },
                        React.createElement("div", { className: classes.navTheme }))),
                React.createElement("div", { className: classes.user },
                    intl.formatMessage({ id: 'common.goodDay', defaultMessage: 'Доброго дня' }),
                    firstName && `, ${firstName}`)),
            React.createElement("div", { className: classes.navBody },
                React.createElement(MenuItems, { setIsMenu: setIsMenu }),
                React.createElement("div", { className: classes.exit, onClick: () => authLogoutRequest() },
                    React.createElement("div", { className: classes.exitIcon },
                        React.createElement(ExitIcon, null)),
                    intl.formatMessage({ id: 'common.exit', defaultMessage: 'Вийти' })),
                React.createElement(Lang, { handleChangeLang: handleChangeLang })))));
};
export default Header;
