import React, { useState } from 'react';
import classes from './CollapsibleItem.module.scss';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import globalClasses from '@/containers/App/Global.module.scss';
const CollapsibleItem = ({ title, totalValue, totalCurrency, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handledIsOpen = () => {
        if (children)
            setIsOpen((prev) => !prev);
    };
    return (React.createElement("div", { className: classes.collapsible },
        React.createElement("div", { className: classes.collapsibleHeader },
            React.createElement("div", { className: classes.collapsibleTitle }, title),
            React.createElement("div", { className: classes.collapsibleHeaderRight, onClick: handledIsOpen },
                totalValue && React.createElement("span", null, totalValue),
                totalCurrency && React.createElement("span", null,
                    React.createElement(ValueWithCurrency, { value: totalCurrency, minimumFractionDigits: true, withZero: true })),
                children && React.createElement("div", { className: `${globalClasses.openCloseBtn} ${isOpen ? globalClasses.open : ''}` }))),
        isOpen && children && React.createElement("div", { className: classes.collapsibleContent }, children)));
};
export default CollapsibleItem;
