import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import CollapsibleItem from '../CollapsibleItem/CollapsibleItem';
import classes from '../Finances.module.scss';
import Preloader from '@/atoms/Preloader/Preloader';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_USER_REVISE } from '@/graphql/finances';
const Balance = ({ dateInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    const intl = useIntl();
    const { loading, error, data } = useQuery(GET_USER_REVISE, {
        variables: {
            getUserReviseInput: {
                week: dateInfo.week,
                year: dateInfo.year
            }
        },
        skip: !dateInfo.week || !dateInfo.year
    });
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement("div", { style: { marginTop: 20 } },
            React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }));
    const totalRevenue = (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _a === void 0 ? void 0 : _a.revise) === null || _b === void 0 ? void 0 : _b.revenueByTariffRules) === null || _c === void 0 ? void 0 : _c.totalRevenue) !== null && _d !== void 0 ? _d : null;
    const driverPayableSumsWithTariffRules = (_h = (_g = (_f = (_e = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _e === void 0 ? void 0 : _e.revise) === null || _f === void 0 ? void 0 : _f.revenueByTariffRules) === null || _g === void 0 ? void 0 : _g.driverPayableSumsWithTariffRules) !== null && _h !== void 0 ? _h : [];
    const tariffTax = (_m = (_l = (_k = (_j = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _j === void 0 ? void 0 : _j.revise) === null || _k === void 0 ? void 0 : _k.revenueByTariffRules) === null || _l === void 0 ? void 0 : _l.tariffTax) !== null && _m !== void 0 ? _m : null;
    const accounting = (_r = (_q = (_p = (_o = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _o === void 0 ? void 0 : _o.revise) === null || _p === void 0 ? void 0 : _p.revenueByTariffRules) === null || _q === void 0 ? void 0 : _q.accounting) !== null && _r !== void 0 ? _r : null;
    const totalBonuses = (_v = (_u = (_t = (_s = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _s === void 0 ? void 0 : _s.revise) === null || _t === void 0 ? void 0 : _t.revenueByTariffRules) === null || _u === void 0 ? void 0 : _u.totalBonuses) !== null && _v !== void 0 ? _v : null;
    const rewards = (_z = (_y = (_x = (_w = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _w === void 0 ? void 0 : _w.revise) === null || _x === void 0 ? void 0 : _x.integrationsRevenue) === null || _y === void 0 ? void 0 : _y.rewards) !== null && _z !== void 0 ? _z : null;
    const driverBonuses = (_4 = (_3 = (_2 = (_1 = (_0 = data === null || data === void 0 ? void 0 : data.getUserRevise) === null || _0 === void 0 ? void 0 : _0.revise) === null || _1 === void 0 ? void 0 : _1.revenueByTariffRules) === null || _2 === void 0 ? void 0 : _2.fleetBonuses) === null || _3 === void 0 ? void 0 : _3.driverBonuses) !== null && _4 !== void 0 ? _4 : null;
    return (React.createElement("div", { className: classes.balance },
        React.createElement("div", { className: classes.subTitle }, intl.formatMessage({ id: 'common.driverIncome', defaultMessage: 'Прибуток водія' })),
        React.createElement(CollapsibleItem, { totalCurrency: totalRevenue, title: intl.formatMessage({ id: 'common.driverIncome', defaultMessage: 'Прибуток водія' }) },
            (driverPayableSumsWithTariffRules === null || driverPayableSumsWithTariffRules === void 0 ? void 0 : driverPayableSumsWithTariffRules.length) && (React.createElement("div", { className: classes.table },
                React.createElement("div", { className: classes.tableHead },
                    React.createElement("div", { className: classes.tableCell }, intl.formatMessage({ id: 'common.typeOfRate', defaultMessage: 'Тип ставки' })),
                    React.createElement("div", { className: classes.tableCell }, intl.formatMessage({ id: 'common.profit', defaultMessage: 'Прибуток' }))),
                React.createElement("div", { className: classes.tableBody }, driverPayableSumsWithTariffRules.map((rule) => (React.createElement("div", { key: uuidv4(), className: classes.tableRow },
                    React.createElement("div", { className: classes.tableCell },
                        rule.tariffRule.from,
                        rule.tariffRule.to >= 1000000 ? '+' : `-${rule.tariffRule.to}`,
                        ", ",
                        rule.tariffRule.rate,
                        "%"),
                    React.createElement("div", { className: classes.tableCell },
                        React.createElement(ValueWithCurrency, { value: rule.driverPayableSumByTariff, separationValue: true, minimumFractionDigits: true, withZero: true })))))))),
            tariffTax != null && tariffTax !== 0 && (React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.rowTitle }, intl.formatMessage({ id: 'common.tax', defaultMessage: 'Податок' })),
                React.createElement("div", { className: classes.rowValue },
                    React.createElement(ValueWithCurrency, { value: tariffTax, separationValue: true, minimumFractionDigits: true, withZero: true })))),
            accounting != null && accounting !== 0 && (React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.rowTitle }, intl.formatMessage({ id: 'common.accounting', defaultMessage: 'Бухгалтерія' })),
                React.createElement("div", { className: classes.rowValue },
                    React.createElement(ValueWithCurrency, { value: accounting, separationValue: true, minimumFractionDigits: true, withZero: true })))),
            totalBonuses != null && totalBonuses !== 0 && (React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.rowTitle }, intl.formatMessage({ id: 'common.bonusFromAggregator', defaultMessage: 'Бонус від агрегатора' })),
                React.createElement("div", { className: classes.rowValue },
                    React.createElement(ValueWithCurrency, { value: totalBonuses, separationValue: true, minimumFractionDigits: true, withZero: true })))),
            rewards != null && rewards !== 0 && (React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.rowTitle }, intl.formatMessage({ id: 'common.driverReward', defaultMessage: 'Нагорода водія' })),
                React.createElement("div", { className: classes.rowValue },
                    React.createElement(ValueWithCurrency, { value: rewards, separationValue: true, minimumFractionDigits: true, withZero: true })))),
            driverBonuses != null && driverBonuses !== 0 && (React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.rowTitle }, intl.formatMessage({ id: 'common.bonusesForTrips', defaultMessage: 'Бонуси за поїздки' })),
                React.createElement("div", { className: classes.rowValue },
                    React.createElement(ValueWithCurrency, { value: driverBonuses, separationValue: true, minimumFractionDigits: true, withZero: true })))))));
};
export default Balance;
