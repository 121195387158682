import React from 'react';
import { NUM_LOCALE } from '../../utils/constants';
import { getCurrencySymbol } from '@/utils/helpers';
const ValueWithCurrency = ({ value, minimumFractionDigits, maximumFractionDigits, withZero, currencySymbol, separationValue }) => {
    var _a;
    const formattedValue = value === null || value === void 0 ? void 0 : value.toLocaleString(NUM_LOCALE, Object.assign(Object.assign({}, minimumFractionDigits && { minimumFractionDigits: 2 }), maximumFractionDigits && { maximumFractionDigits: 0 }));
    const [wholePart, decimalPart] = (_a = formattedValue === null || formattedValue === void 0 ? void 0 : formattedValue.split('.')) !== null && _a !== void 0 ? _a : [];
    if (!formattedValue && withZero) {
        return React.createElement(React.Fragment, null, "0");
    }
    if (!formattedValue) {
        return React.createElement(React.Fragment, null, "\u2014");
    }
    return React.createElement("div", { style: { display: 'inline-flex', alignItems: 'center' } },
        currencySymbol && React.createElement("p", { style: { margin: '0 4px 0 0' } }, getCurrencySymbol(currencySymbol !== null && currencySymbol !== void 0 ? currencySymbol : '')),
        separationValue
            ? (React.createElement(React.Fragment, null,
                wholePart,
                decimalPart && React.createElement("span", { className: 'decimal-part' },
                    ".",
                    decimalPart)))
            : formattedValue);
};
export default ValueWithCurrency;
