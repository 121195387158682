import { Tabs } from 'antd';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/isoWeek';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Balance from './Balance/Balance';
import classes from './Finances.module.scss';
import Income from './Income/Income';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import globalClasses from '@/containers/App/Global.module.scss';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/tg';
import 'dayjs/locale/uk';
import 'dayjs/locale/uz';
import { getLocale } from '@/utils/helpers';
dayjs.extend(weekOfYear);
const getItems = ({ intl, dateInfo }) => {
    return [
        {
            key: 'income',
            label: intl.formatMessage({ id: 'common.income', defaultMessage: 'Дохід' }),
            children: React.createElement(Income, { dateInfo: dateInfo })
        },
        {
            key: 'balance',
            label: intl.formatMessage({ id: 'common.balanceTitle', defaultMessage: 'Баланс' }),
            children: React.createElement(Balance, { dateInfo: dateInfo })
        }
    ];
};
const Finances = () => {
    const [dateInfo, setDateInfo] = useState(() => {
        const currentDate = dayjs();
        return {
            date: currentDate,
            week: currentDate.isoWeek(),
            year: currentDate.isoWeekYear()
        };
    });
    const { lang } = useAppSelector((state) => state.auth.user);
    const intl = useIntl();
    useEffect(() => {
        dayjs.locale(getLocale(lang).toLocaleLowerCase() || 'en');
    }, [lang]);
    const items = getItems({ intl, dateInfo });
    const changeWeek = (direction) => {
        const updatedDate = direction === 'next'
            ? dateInfo.date.add(1, 'week')
            : dateInfo.date.subtract(1, 'week');
        setDateInfo({
            date: updatedDate,
            week: updatedDate.isoWeek(),
            year: updatedDate.isoWeekYear()
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: globalClasses.topBox },
            React.createElement(Breadcrumb, { items: [{
                        id: 'home',
                        path: routes.cabinet.f,
                        text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                    }] }),
            React.createElement("div", { className: globalClasses.title }, intl.formatMessage({ id: 'common.finances', defaultMessage: 'Финансы' })),
            React.createElement("div", { className: classes.calendarBtn },
                React.createElement("div", { onClick: () => { changeWeek('prev'); }, className: classes.calendarBtnArrow },
                    React.createElement(ArrowIcon, null)),
                `${dateInfo.week} ${intl.formatMessage({ id: 'common.week', defaultMessage: 'тиждень' })} ${dateInfo.year}`,
                React.createElement("div", { onClick: () => { changeWeek('next'); }, className: classes.calendarBtnArrow },
                    React.createElement(ArrowIcon, null)))),
        React.createElement("div", { className: globalClasses.container },
            React.createElement(Tabs, { defaultActiveKey: "income", items: items }))));
};
export default Finances;
