import React from 'react';
import BoltIcon from '../../../icons/bolt.svg';
import CareemIcon from '../../../icons/careem.svg';
import UberIcon from '../../../icons/uber.svg';
import UklonIcon from '../../../icons/uklon.svg';
import YangoIcon from '../../../icons/yango.svg';
export const vendorsIcons = (vendor) => {
    var _a;
    return ((_a = {
        BOLT: React.createElement(BoltIcon, null),
        UKLON: React.createElement(UklonIcon, null),
        UBER: React.createElement(UberIcon, null),
        CAREEM: React.createElement(CareemIcon, null),
        YANGO: React.createElement(YangoIcon, null)
    }[vendor]) !== null && _a !== void 0 ? _a : null);
};
export const vendorsColors = (vendor) => {
    var _a;
    return ((_a = {
        BOLT: '#34D186',
        UKLON: '#F7C844',
        UBER: '#1A1A1A',
        OFFICE_TRIPS: '#3C7EFF',
        CAREEM: '#00EB79',
        YANGO: '#FE0000'
    }[vendor]) !== null && _a !== void 0 ? _a : '#1A1A1A');
};
