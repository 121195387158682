export const DEFAULT_TIMEZONE = 'Europe/Kiev';
export const NUM_LOCALE = 'en-US';
export const MOBILE_WIDTH = 1200;
export const routes = {
    auth: {
        s: 'auth',
        f: '/auth'
    },
    login: {
        s: 'login',
        f: '/login'
    },
    registration: {
        s: 'invite',
        f: '/invite/:inviteId'
    },
    resetPassword: {
        s: 'reset-password-link',
        f: '/reset-password-link/:resetPasswordId'
    },
    blocked: {
        s: 'blocked',
        f: '/blocked'
    },
    errorPage: {
        s: 'error-page',
        f: '/error-page'
    },
    // PAGES
    cabinet: {
        s: 'cabinet',
        f: '/cabinet'
    },
    carTransfer: {
        s: 'car-acceptance-transfer',
        f: '/car-acceptance-transfer'
    },
    returnCar: {
        s: 'return-car',
        f: '/return-car'
    },
    officeTrips: {
        s: 'office-trips',
        f: '/office-trips'
    },
    createOfficeTrips: {
        s: 'create-office-trips',
        f: '/create-office-trips'
    },
    cars: {
        s: 'cars',
        f: '/cars'
    },
    finances: {
        s: 'finances',
        f: '/finances'
    },
    joinRoutes: (...params) => `/${params.join('/')}`.replace(/\/+/g, '/')
};
export const languages = [
    { id: 'UA', label: 'Українська' },
    { id: 'EN', label: 'English' },
    { id: 'PL', label: 'Polski' },
    { id: 'TG', label: 'Тоҷикӣ' },
    { id: 'UZ', label: 'О\'zbek' },
    { id: 'RU', label: 'Русский' }
];
