import { gql } from '@apollo/client';
export const GET_USER_INCOME = gql `
  query GetUserIncome($getUserIncomeInput: GetUserIncomeInput!) {
    getUserIncome(getUserIncomeInput: $getUserIncomeInput) {
      income {
        totalIncome
        totalTrips
        incomeWithoutSplitExpenses
        integrationDailyStatements {
          date
          vendor
          totalIncome
          totalTrips
        }
        splitExpenses {
          total
        }
      }
    }
  }
`;
export const GET_USER_REVISE = gql `
  query GetUserRevise($getUserReviseInput: GetUserReviseInput!) {
    getUserRevise(getUserReviseInput: $getUserReviseInput) {
      revise {
        revenueByTariffRules {
          totalBonuses
          totalRevenue
          tariffTax
          accounting
          driverPayableSumsWithTariffRules {
            driverPayableSumByTariff
            tariffRule {
              from
              to
              rate
            }
          }
          fleetBonuses {
            driverBonuses
          }
        }
        integrationsRevenue {
          rewards
        }
      }
    }
  }
`;
